.PostPage h1 {
  margin: 0.25rem 0;
  font-size: 1.75rem;
  overflow-wrap: break-word;
}
.Date {
  margin: 0;
  font-weight: lighter;
}

.Kryptex img {
  width: 100%;
  max-width: 900px;
}
@media (max-width: 768px) {
  .Kryptex {
    display: none;
  }
}
.Content p {
  margin: 1.5rem 0;
  line-height: 1.4;
}

.Content sup {
  vertical-align: top;
  font-size: 0.9em;
  position: relative;
  top: -0.2em;
}

.Content ol,
.Content ul {
  margin: 0.5rem 0;
  padding-left: 1rem;
  list-style-position: outside;
}

.Content ol li,
.Content ul li {
  margin: 0.5rem 0;
}

.Content em {
  font-weight: lighter;
  letter-spacing: initial;
}

.Content h1,
.Content h2,
.Content h3,
.Content h4,
.Content h5,
.Content h6 {
  color: var(--highlight-alt);
}

.Content h1 {
  font-size: 2em;
  text-transform: none;
  margin: 2rem 0;
}
.Content h2 {
  font-size: 1.5em;
  margin: 1.5rem 0;
}
.Content h3 {
  font-size: 1.17em;
  margin: 1.7rem 0;
}
.Content h4,
.Content h5,
.Content h6 {
  font-size: 1em;
  text-transform: none;
  margin: 1rem 0;
}
.Content h5 {
  font-weight: normal;
}
.Content h6 {
  font-weight: lighter;
}

.Content a {
  color: var(--highlight-content-link);
  transition: opacity 0.1s ease-in-out;
}
.Content a:hover {
  opacity: 0.7;
}

.Content p,
.Content li {
  line-height: 1.75rem;
  letter-spacing: 0.5px;
}

.Content p code {
  border-radius: 5px;
  border: 1px solid var(--background-6);
  padding: 2px 4px;
  background: var(--background-2);
  white-space: nowrap;
}

.Content aside {
  border: 2px solid black;
  padding: 15px;
  margin: 16px 0;
  border-radius: 8px;
  background-color: var(--background-4);
}

.Content aside > h3 {
  margin: 0;
  color: var(--highlight);
}

.Content aside > div {
  margin: 10px 0 0 0;
}

.Taglist {
  margin: 1rem 0;
  width: 100%;
  max-width: 900px;
}

.Tag {
  display: inline-block;
  margin-right: 1rem;
  margin-top: 1rem;
  background: var(--highlight);
  padding: 0.25rem 0.5rem;
  color: var(--text-color);
  border-radius: 4px;
}
