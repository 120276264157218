.Footer {
  width: 100%;
  color: var(--text-color);
  background: linear-gradient(var(--background-2), var(--background-1));
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: space-around;
  margin-bottom: 55px;
  padding: 1rem 2rem;
  font-size: 18px;
}

.Footer a:hover {
  opacity: 0.7;
}

.Footer ul {
  list-style: none;
}

.Links > li {
  margin: 1rem 0;
}
.ProjectLinks {
  margin-top: -0.5rem;
  font-size: 14px;
}
.Mobile,
.NoMobile {
  font-size: 14px;
  font-weight: lighter;
}

.Mobile {
  margin-top: 1rem;
  display: flex;
  flex-flow: column;
}

.Mobile > span {
  margin-right: 5px;
}
.NoMobile {
  margin-top: 2rem;
  display: none;
  flex-flow: column;
}

.NoMobile > *,
.Mobile > * {
  margin: 0.25rem 0;
}

.NoMobile a,
.Mobile a {
  color: var(--highlight);
}

@media (min-width: 550px) {
  .Footer {
    flex-flow: row;
    margin-bottom: 0;
  }
  .Mobile {
    display: none;
  }
  .NoMobile {
    display: flex;
  }
}

.Socials {
  display: inline-block;
  font-size: 25px;
}
.Socials > span {
  display: flex;
  flex-flow: row;
  gap: 1rem;
}
