.Search {
  --searchbar-height: 35px;
  margin: 0;
  position: relative;
}

.Search input {
  height: var(--searchbar-height);
  padding: 0 12px;
  border: none;
  outline: none;
  background-color: var(--background-6);
  color: var(--text-color);
  border-radius: 4px;
  width: 30%;
  min-width: 150px;
  transition: all 0.5s ease-in-out;
}

.Search input::placeholder {
  color: var(--text-color);
  opacity: 0.5;
}

.Search input:focus,
.Search input.ResultsShown {
  width: 100%;
  border-radius: 4px 4px 0 0;
}
.Label {
  margin: 0.5rem 0;
}

.Results {
  position: absolute;
  width: 100%;
  background: var(--background-2);
  padding-top: 4rem;
  border-radius: 0 0 8px 8px;
  z-index: 10;
}

.Results > *:not(.NoResults) {
  padding: 0.5rem 1rem;
  background: var(--background-6);
  margin: 1rem;
  border-radius: 8px;
}

.NoResults {
  padding: 0rem 1rem 1rem 1rem;
  margin-top: -2rem;
  border-radius: 8px;
}

.CloseButton {
  position: absolute;
  right: 1rem;
  top: 0;
  font-size: 20px;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  padding: 0 !important;
  vertical-align: middle;
}
